#root{
    height: 100vh;
}

body,html{
    margin: 0;
    padding: 0;
}

body{
    
    /*
    background: linear-gradient(0.25turn,rgba(0,130,170,0),rgba(0,130,170,0),rgba(23,80,124,0.4)), url("/src/Components/Assets/backgroundTile.png");
    */

    
    background: linear-gradient(0.25turn, rgba(0,0,0,0.4),rgba(0,130,170,0),rgba(0,130,170,0),rgba(0,130,170,0),rgba(0,0,0,0.4)), url("/src/Components/Assets/backgroundTile.png");
    

    /*
    background-image: url("/src/Components/Assets/CRMBackgroundscreen.jpg");
    background-size: cover;
    */
}

#container{
    margin-top: 20px;
}

#nav-col{
    max-width: 120px;
}

#content-col{
    margin-bottom: 20px
}

#content-top-stack{
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

#content{
    padding: 10px;
    background-color: #fff;
    border-radius: 20px;
    width: 100%;
}

.content-btn{
    max-width: 130px;
    max-height: 50px;
    font-weight: bold !important;
    font-size: 1.3rem !important;
}

.input-group-text{
    background-color: #dddddd !important;
    border: 1px solid #bdbdbd !important;
}

.form-control{
    border: 1px solid #bdbdbd !important;
}

.form-hr{
    border: none;
    height: 5px;
    color: #a9a9a9;
    background-color: #a9a9a9;
    opacity: 1;
    margin-bottom: 30px;
}

.form-label{
    font-size: 25px;
    color: grey;
}

.form-text-input{
    font-size: 1.5rem !important;
    font-weight: bold !important;
    border: 5px solid #3a8dc6 !important;
    border-radius: 10px !important;
    min-height: 60px;
}

.form-text-input:focus{
    border: 5px solid #0068ff !important;
}

.btn_active {
    width: 100%;
    height: 100px;
}

.btn {
    width: 100%;
    height: 100px;
    margin-bottom: 15px;
}

/* Breakpoints */
@media (min-width: 0px) {
    #content-col{
        margin-right: 0px;
    }

    #nav-col{
        max-width: 100%;
    }

    .margin-end-xs-md{
        margin-right: auto !important;
    }
}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {
    #nav-col{
        max-width: 100px;
    }

    #content-col{
        margin-right: 20px;
    }

    .margin-end-xs-md{
        margin-right: unset !important;
    }
}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}