.epc-table-container{
    background: #d9d9d9;
    border-radius: 5px;
    padding: 10px;
    min-width: 500px;
}

.epc-table{
    margin-bottom: 0px !important;
    --bs-table-bg: transparent !important;
    --bs-table-border-color: transparent !important;
}

.epc-table-icon-td{
    width: 150px;
    vertical-align: middle;
    padding: 2px;
}

.epc-table-icon{
    width: 125px;
    height: 125px;
}

.epc-table-title{
    font-weight: bold;
    font-size: 1.3rem;
}

.epc-table-label{
    border: 2px solid #8c8c8c;
    background-image: linear-gradient(#e1e1e1, white, white);
    border-radius: 5px;
    box-shadow: inset 0px 3px #00000014;
    padding: 5px;
}

.epc-table-epc-label{
    font-weight: bold;
    font-size: 0.85rem;
    color: #717171 !important;
}

.epc-table-td{
    padding: 3px !important;
}

.epc-table-write-btn{
    max-height: 50px;
    font-size: 1.50rem !important;
    color: #ffffff !important;
    margin-bottom: 0px;
}