#kits-icon{
    max-width: 70px;
}

#kits-title{
    font-size: 1.2rem;
    font-weight: bold;
    margin: 10px;
}

#kits-page{
    font-size: 1.2rem;
    font-weight: bold;
}

#add-new-kit-btn{
    width: 200px;
    height: 50px;
    margin-bottom: 0px;
}

.page-button{
    width: 200px;
    height: 50px;
    margin-bottom: 0px;
    font-weight: bold !important;
    margin: 10px;
}