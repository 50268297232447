#debug-icon{
    max-width: 70px;
}

#debug-title{
    font-size: 1.2rem;
    font-weight: bold;
}

#add-new-entry-btn{
    width: 200px;
    height: 50px;
    margin-bottom: 0px;
}