.dashboard-item-card{
    width: 18rem !important;
    margin: 10px;
}

.dashboard-item-img{
    max-width: 100px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.dashboard-item-button{
    max-height: 50px;
    margin-bottom: 0px;
}

.card-text{
    min-height: 50px;
}