.array-table-epc{
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    color: #08275b !important;
}

.array-table-kit-btn{
    height: 35px;
    font-size: 1.0rem;
    margin: 0px !important;
}

.array-table-body-td{
    vertical-align: middle;
}

#array-table > thead > tr > th::first-letter {
    text-transform: uppercase;
}

