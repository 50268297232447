#loading-div{
    color: white;
    width: 300px;
    margin: auto;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 20px;
}

#content{
    width: 90%;
    margin: auto;
}

#array-table{
    margin-top: 20px;
}

#companies-icon{
    max-width: 70px;
}

#companies-title{
    font-size: 1.2rem;
    font-weight: bold;
}

#add-new-company-btn{
    width: 200px;
    height: 50px;
    margin-bottom: 0px;
}