#kit-material-container{
    max-width: 800px;
}

#kit-material-hr{
    border: none;
    height: 5px;
    color: #a9a9a9;
    background-color: #a9a9a9;
    opacity: 1;
    margin-bottom: 20px;
}

.kit-material-item{
    margin-bottom: 20px;
    background: #d9d9d9;
    border-radius: 5px;
}

.kit-material-delete-btn{
    font-weight: bold !important;
    max-width: 175px;
    max-height: 45px;;
}

.kit-materal-extra-stack{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 10px;
}

.kit-material-add-btn{
    font-weight: bold !important;
    max-width: 175px;
    max-height: 45px;;
    margin-bottom: 25px;
    width: 250px;
}

.kit-material-add-btn-link{
    margin-right: 30px;
}