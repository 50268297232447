#add-kit-material-form > div.react-datepicker-wrapper > div > input{
    border-radius: 5px;
    border: 2px solid #a1a1a1;
    padding: 5px;
    font-weight: bold;
    font-size: 1.2rem;
}

#add-kit-material-form{
    max-width: 500px;
    padding: 10px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 20px;
}

#add-kit-material-logo{
    max-width: 120px;
}

#add-kit-material-btn{
    font-weight: bold;
    max-width: 250px;
    max-height: 50px;
    margin-top: 30px;
}

.lp-copy-sel__option, .lp-copy-sel__single-value{
    white-space: pre-wrap !important;
    font-weight: bold;
}

.lp-copy-sel__value-container {
    height:5em;
}

.add-kit-material-select-placeholder{
    font-weight: bold;
    color: hsl(0, 0%, 20%) !important;
}

.add-kit-material-selected-option__control{
    font-weight: bold;
    color: hsl(0, 0%, 20%) !important;
}

.add-kit-material-form-label{
    font-weight: bold;
    font-size: 1.2rem;
    color: hsl(0, 0%, 20%) !important;
    margin-bottom: 0px !important;
}

.add-kit-material-form-input{
    font-weight: bold !important;
    font-size: 1.2rem !important;
    color: hsl(0, 0%, 20%) !important;
    max-width: 250px;
    text-align:center;
}

.add-kit-material-selected-option__value-container{
    height:3em;
}

.add-kit-material-select-max-250{
    max-width: 250px;
}