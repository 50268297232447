#logo-header-container{
	padding-bottom: 60px;
    padding-top: 20px;
}

#header-logo{
	margin: 15px;
}

#header-btn{
	margin-top: 80px;
	font-weight: bold;
	max-width: 100px;
	max-height: 50px;
	border: 3px solid #89b8ff;
}

#header-icon{
	width: 30px;
	height: 30px;
}

/* Breakpoints */
@media (min-width: 0px) {

	#header-logo{
		max-width: 250px;
		max-height: 80px;
	}
}

@media (min-width: 576px) {

	#header-logo{
		max-width: 350px;
		max-height: 120px;
	}
}

@media (min-width: 768px) {

	#header-logo{
		max-width: 500px;
		max-height: 200px;
	}
}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}