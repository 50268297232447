#ship-kit-form{
    max-width: 500px;
    padding: 10px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
}

#ship-kit-logo{
    max-width: 120px;
}

#ship-kit-button{
    font-weight: bold;
    max-width: 250px;
    max-height: 50px;
}

#ship-kit-text{
    font-weight: bold;
    margin-bottom: 40px;
    margin-top: 20px;
    font-size: 1.2rem;
}

#ship-kit-btn-stack{
    margin-left: 15px;
    margin-right: 15px;
}