#search-form{
    max-width: 500px;
    padding: 10px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 20px;
}

#search-logo{
    max-width: 120px;
}

.search-form-label{
    font-weight: bold;
    font-size: 1.2rem;
    color: hsl(0, 0%, 20%) !important;
    margin-bottom: 0px !important;
}

.search-form-input{
    font-weight: bold !important;
    font-size: 1.2rem !important;
    color: hsl(0, 0%, 20%) !important;
    max-width: 350px;
    text-align:center;
}

#search-btn{
    font-weight: bold;
    max-width: 250px;
    max-height: 50px;
    margin-top: 30px;
}

#search-icon{
    max-width: 70px;
    max-height: 70px;
}

#search-title{
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px;
}

.search-sub-title{
    font-weight: bold;
    text-align: center;
    border-top: 3px solid #afafaf;
    border-bottom: 3px solid #afafaf;
    background: #efefef;
    padding: 10px;
    max-width: 50%;
    margin: auto;
}
