#decode-form{
    max-width: 500px;
    padding: 10px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 20px;
}

#decode-logo{
    max-width: 75px;
}

#decode-epc-table-container{
    max-width: 900px;
}

.decode-form-label{
    font-weight: bold;
    font-size: 1.2rem;
    color: hsl(0, 0%, 20%) !important;
    margin-bottom: 0px !important;
}

.decode-form-input{
    font-weight: bold !important;
    font-size: 1.2rem !important;
    color: hsl(0, 0%, 20%) !important;
    max-width: 400px;
    text-align:center;
}

#decode-button{
    font-weight: bold !important;
    max-width: 250px;
    max-height: 50px;
    margin-top: 30px;
}

.decode-read-button{
    font-weight: bold !important;
    max-width: 250px;
    max-height: 50px;
    margin-top: 30px;
}