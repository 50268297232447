#login-root-div{
    min-height: 850px;
}

#fusion_logo{
    margin-top: 100px;
    padding: 15px;
    max-width: 400px;
    float: right;
}

#login-logo{
    width: 90%;
    margin-top: 15px;
    margin-bottom: 10px;
}

#login-form{
    max-width: 550px;
    min-width: 400px;
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
}

#login-button{
    max-width: 300px;
    max-height: 70px;
    font-size:30px;
    margin-top: 30px;
}

#error-alert{
    max-width: 500px;
    margin-top: 30px;
}

#login-checkbox{
    border: 3px solid #3a8dc6;
    padding: 10px;
    margin-right: 10px;
}

#login-checkbox-group > div > label{
    height: 30px;
    line-height: 35px !important;
}

#login-subtitle{
    color: grey;
    font-size: 26px;
    margin-top: 40px;
}

#login-forgot{
    height: 45px;
    font-size: 15.7px;
    text-decoration: none !important;
    padding: 0px !important;
    line-height: 28px;
    color: #212529;
    margin-right: 13px;
}

#login-side-logo{
    max-width: 700px;
    margin-top: 300px;
    width: 100%;
    padding: 10px;
}

/* Breakpoints */
@media (min-width: 0px) {}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}